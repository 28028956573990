/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.7.2/lightgallery.umd.min.js
 * - /npm/lg-autoplay@1.2.1/dist/lg-autoplay.min.js
 * - /npm/lg-fullscreen@1.2.1/dist/lg-fullscreen.min.js
 * - /npm/lg-thumbnail@1.2.1/dist/lg-thumbnail.min.js
 * - /npm/lg-video@1.4.0/dist/lg-video.min.js
 * - /npm/lg-zoom@1.3.0/dist/lg-zoom.min.js
 * - /npm/lg-pager.js@1.0.0/dist/lg-pager.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
